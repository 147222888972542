import { Box, Button, MenuItem, Select, TextField, Typography, useTheme, Switch, FormControlLabel } from "@mui/material";
import { forwardRef, useState } from "react";
import { UserCategory } from "../../types";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Color from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import ListItem from "@tiptap/extension-list-item";
import Image from "@tiptap/extension-image";
import BaseEditor from "../Editor/BaseEditor";

interface EditProductFormState {
    name: string;
    userCategory: UserCategory;
    description: string;
    availableOnline?: boolean;
    brand?: string;
    isMedical?: boolean;
}

export interface EditProductFormProps {
    productId: string;
    initialValues: {
        name: string;
        userCategory: UserCategory;
        description?: string;
        availableOnline?: boolean;
        brand?: string;
        isMedical?: boolean;
    };
    onSubmit: (productId: string, values: EditProductFormState) => void;
}

const EditProductForm = forwardRef(({ productId, initialValues, onSubmit }: EditProductFormProps, ref) => {
    const theme = useTheme();
    const [formState, setFormState] = useState<EditProductFormState>({
        name: initialValues.name,
        userCategory: initialValues.userCategory,
        description: initialValues.description || '',
        availableOnline: initialValues.availableOnline ?? false,
        isMedical: initialValues.isMedical ?? false,
        brand: initialValues.brand
    });

    const editor = useEditor({
        extensions: [
            StarterKit,
            Color.configure({ types: [TextStyle.name, ListItem.name] }),
            TextStyle,
            Image.configure({
                inline: true,
                allowBase64: true,
            })
        ],
        content: initialValues.description || '',
        onUpdate: ({ editor }) => {
            setFormState(prev => ({
                ...prev,
                description: editor.getHTML()
            }));
        }
    });

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onSubmit(productId, formState);
    };

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 600,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 1,
                maxHeight: '90vh',
                overflowY: 'auto'
            }}
            ref={ref}
        >
            <Typography variant="h6" component="h2" gutterBottom>
                Edit Product
            </Typography>

            <TextField
                fullWidth
                label="Name"
                value={formState.name}
                onChange={(e) => setFormState({ ...formState, name: e.target.value })}
                margin="normal"
                required
            />

            <TextField
                fullWidth
                label="Brand"
                value={formState.brand}
                onChange={(e) => setFormState({ ...formState, brand: e.target.value })}
                margin="dense"
                sx={{ mt: 2, mb: 1 }}
            />

            <Select
                fullWidth
                value={formState.userCategory}
                onChange={(e) => setFormState({ ...formState, userCategory: e.target.value as UserCategory })}
                margin="dense"
                sx={{ mt: 2, mb: 1 }}
            >
                {Object.values(UserCategory).map((category) => (
                    <MenuItem key={category} value={category}>
                        {category}
                    </MenuItem>
                ))}
            </Select>

            <FormControlLabel
                control={
                    <Switch
                        checked={formState.availableOnline}
                        onChange={(e) => setFormState({ ...formState, availableOnline: e.target.checked })}
                    />
                }
                label="Available Online"
                sx={{ mt: 1, mb: 1 }}
            />
            <FormControlLabel
                control={
                    <Switch
                        checked={formState.isMedical}
                        onChange={(e) => setFormState({ ...formState, isMedical: e.target.checked })}
                    />
                }
                label="Medical Product"
                sx={{ mt: 1, mb: 1 }}
            />

            <Box sx={{ 
                mt: 2,
                mb: 1,
                '& .ProseMirror': {
                    minHeight: '200px',
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: 1,
                    padding: 2,
                    overflowX: 'hidden',
                    wordWrap: 'break-word',
                    '&:focus': {
                        outline: 'none',
                        borderColor: theme.palette.primary.main,
                    }
                },
                '& .tiptap': {
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    width: '100%',
                    overflowX: 'hidden'
                },
                '& .tiptap-menubar': {
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    padding: 1,
                    overflowX: 'auto',
                    '&::-webkit-scrollbar': {
                        height: 6
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.divider,
                        borderRadius: 3
                    }
                }
            }}>
                <Typography variant="subtitle1" gutterBottom>
                    Description
                </Typography>
                {editor && <BaseEditor editor={editor} />}
            </Box>

            <Button 
                type="submit"
                variant="contained"
                sx={{ mt: 2 }}
                fullWidth
            >
                Save Changes
            </Button>
        </Box>
    );
});

export default EditProductForm; 