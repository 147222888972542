import { LocationsState } from "../types/state";
import { FETCH_LOCATIONS_FAILURE, FETCH_LOCATIONS_PRODUCTS_FAILURE, FETCH_LOCATIONS_PRODUCTS_START, FETCH_LOCATIONS_PRODUCTS_SUCCESS, FETCH_LOCATIONS_START, FETCH_LOCATIONS_SUCCESS, SET_QUERY, SET_SELECTED_LOCATION, UPDATE_LOCATION_PRODUCT_FAILURE, UPDATE_LOCATION_PRODUCT_START, UPDATE_LOCATION_PRODUCT_SUCCESS, RESYNC_LOCATIONS_PRODUCTS_START, RESYNC_LOCATIONS_PRODUCTS_SUCCESS, RESYNC_LOCATIONS_PRODUCTS_FAILURE } from "../types/locations";

const initialState: LocationsState = {
    locations: [],
    locationsProducts: [],
    isSyncingProducts: localStorage.getItem('lastProductSynced') ? true : false,
    query: null,
    isLoading: false,
    error: null,
    selectedLocationId: null,
    totalPages: null,
    facets: [],
};  

const auth = (state = initialState, action: any) => {
    switch (action.type) {
        case FETCH_LOCATIONS_START:
            return {
                ...state,
                error: null,
            };
        case FETCH_LOCATIONS_SUCCESS:
            return {
                ...state,
                locations: action.payload,
                error: null,
            };
        case FETCH_LOCATIONS_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case SET_SELECTED_LOCATION:
            return {
                ...state,
                selectedLocationId: action.payload,
            };
        case FETCH_LOCATIONS_PRODUCTS_START:
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case FETCH_LOCATIONS_PRODUCTS_SUCCESS:
            // @ts-ignore
            const brandFacets = state.facets.find((facet: any) => facet.field_name === 'brand')
            let newFacets = action.payload.facets 
                ? brandFacets 
                    ? [...state.facets.filter(f => f.field_name === 'brand'), ...action.payload.facets.filter((f: any) => f.field_name !== 'brand')]
                    : action.payload.facets
                : state.facets;
            if (JSON.stringify(newFacets) === JSON.stringify(state.facets)) {
                newFacets = state.facets; // Keep the same reference if content hasn't changed
            }
            return {
                ...state,
                locationsProducts: action.payload.products,
                totalPages: action.payload.totalPages,
                facets: newFacets,
                isLoading: false,
                error: null,
            };
        case FETCH_LOCATIONS_PRODUCTS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };  
        case UPDATE_LOCATION_PRODUCT_START:
            return {
                ...state,
                error: null,
            };
        case UPDATE_LOCATION_PRODUCT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                locationsProducts: state.locationsProducts.map((product) => product.product_id === action.payload.productId ? {...product, ...action.payload } : product),
                error: null,
            };
        case UPDATE_LOCATION_PRODUCT_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case SET_QUERY:
            return {
                ...state,
                query: action.payload,
            };
        case RESYNC_LOCATIONS_PRODUCTS_START:
            return {
                ...state,
                isSyncingProducts: true,
                error: null,
            };
        case RESYNC_LOCATIONS_PRODUCTS_SUCCESS:
            return {
                ...state,
                isSyncingProducts: false,
            }
        case RESYNC_LOCATIONS_PRODUCTS_FAILURE:
            return {
                ...state,
                isSyncingProducts: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default auth;