export const FETCH_LOCATIONS_START = "FETCH_LOCATIONS_START";
export const FETCH_LOCATIONS_SUCCESS = "FETCH_LOCATIONS_SUCCESS";
export const FETCH_LOCATIONS_FAILURE = "FETCH_LOCATIONS_FAILURE";

export const SET_SELECTED_LOCATION = "SET_SELECTED_LOCATION";

export const FETCH_LOCATIONS_PRODUCTS_START = "FETCH_LOCATIONS_PRODUCTS_START";
export const FETCH_LOCATIONS_PRODUCTS_SUCCESS = "FETCH_LOCATIONS_PRODUCTS_SUCCESS";
export const FETCH_LOCATIONS_PRODUCTS_FAILURE = "FETCH_LOCATIONS_PRODUCTS_FAILURE";

export const UPDATE_LOCATION_PRODUCT_START = "UPDATE_LOCATION_PRODUCT_START";
export const UPDATE_LOCATION_PRODUCT_SUCCESS = "UPDATE_LOCATION_PRODUCT_SUCCESS";
export const UPDATE_LOCATION_PRODUCT_FAILURE = "UPDATE_LOCATION_PRODUCT_FAILURE";


export const RESYNC_LOCATIONS_PRODUCTS_START = "RESYNC_LOCATIONS_PRODUCTS_START";
export const RESYNC_LOCATIONS_PRODUCTS_SUCCESS = "RESYNC_LOCATIONS_PRODUCTS_SUCCESS";
export const RESYNC_LOCATIONS_PRODUCTS_FAILURE = "RESYNC_LOCATIONS_PRODUCTS_FAILURE";

export const SET_QUERY = "SET_QUERY";

export enum UserCategory {
    Preroll = 'Preroll',
    Flowers = 'Flowers',
    Edibles = 'Edibles',
    Carts = 'Carts',
    Concentrates = 'Concentrates',
    Disposable = 'Disposable',
    Other = 'Other',
}
export interface UpdateLocationProductInput {
    name?: string;
    userCategory?: UserCategory;
    availableOnline?: boolean;
    description?: string;
    images?: string[];
    isMedical?: boolean;
}